<template>
  <v-card>
    <v-card-title class="text-h6">
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        outlined
        class="mr-1"
        @click="create"
        :disabled="isDisabledAllBtn"
      >
        {{ $t("labels.confirm") }}
      </v-btn>
      <v-btn color="error" outlined @click="closeDialog">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <div class="font-weight-medium fs-16px mb-3">Mã giảm giá</div>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model.number="item.voucher_type"
                :items="voucherTypeOptions"
                label="Giảm theo"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-if="item.voucher_type === 2"
                v-model.number="item.voucher_value"
                label="Giảm (%)"
                type="number"
                class="c-input-small"
                outlined
                dense
                hide-details
              ></v-text-field>
              <input-number
                v-else
                v-model.number="item.voucher_value"
                label="Giảm (đ)"
                outlined
                dense
                hide-details
                type="number"
                class="c-input-small"
              />
            </v-col>
            <v-col cols="12" md="6">
              <date-picker-model
                v-model="item.start_time"
                label="Bắt đầu"
                :min-date="currentDate"
              ></date-picker-model>
            </v-col>
            <v-col cols="12" md="6">
              <date-picker-model
                v-model="item.end_time"
                label="Kết thúc"
                :min-date="currentDate"
              ></date-picker-model>
            </v-col>
            <v-col cols="12" md="6">
              <input-number
                v-model.number="item.discount_from"
                label="Áp dụng hóa đơn từ"
                outlined
                dense
                hide-details
                type="number"
                class="c-input-small"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-checkbox
                v-model="item.is_discount_sale_product"
                class="mt-1 ml-1 c-input-small"
                :value="1"
                hide-details
                dense
                label="Áp dụng SP Sale"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="item.note"
                label="Ghi chú"
                class="c-input-small"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <div class="mb-3 d-flex align-center justify-space-between">
            <div class="font-weight-medium fs-16px">Bộ lọc</div>
            <div class="fs-13px">
              Có
              <span class="error--text font-weight-medium">{{ total }}</span>
              khách hàng phù hợp
            </div>
          </div>
          <v-row dense>
            <v-col cols="12" md="6">
              <InputFilter
                :label="$t('labels.phone_number')"
                :placeholder="$t('labels.phone_number')"
                name="phone"
                @onFilter="onFilterChange"
              />
            </v-col>
            <v-col cols="12" md="6">
              <InputFilter
                :label="$t('labels.name')"
                :placeholder="$t('labels.name')"
                name="name"
                @onFilter="onFilterChange"
              />
            </v-col>
            <v-col cols="12" md="6">
              <SelectFilter
                :label="$t('labels.gender')"
                :placeholder="$t('labels.gender')"
                name="gender"
                :options="genderOptions"
                @onFilter="onFilterChange"
              />
            </v-col>
            <v-col cols="12" md="6">
              <InputFilterFromTo
                label="Ngày sinh"
                placeholder="Ngày sinh"
                name="birthday_day"
                @onFilter="onFilterChange"
              />
            </v-col>
            <v-col cols="12" md="6">
              <InputFilterFromTo
                label="Tháng sinh"
                placeholder="Tháng sinh"
                name="birthday_month"
                @onFilter="onFilterChange"
              />
            </v-col>
            <v-col cols="12" md="6">
              <InputFilterFromTo
                label="Năm sinh"
                placeholder="Năm sinh"
                name="year"
                @onFilter="onFilterChange"
              />
            </v-col>
            <v-col cols="12" md="6">
              <InputFilterFromTo
                label="Chi tiêu"
                placeholder="Chi tiêu"
                name="spend"
                @onFilter="onFilterChange"
              />
            </v-col>
            <v-col cols="12" md="6">
              <InputFilterFromTo
                label="Tích điểm"
                placeholder="Tích điểm"
                name="point"
                @onFilter="onFilterChange"
              />
            </v-col>
            <v-col cols="12" md="6">
              <InputFilterFromTo
                label="Đã sử dụng"
                placeholder="Đã sử dụng"
                name="used_point"
                @onFilter="onFilterChange"
              />
            </v-col>
            <v-col cols="12" md="6">
              <InputFilterFromTo
                label="Khả dụng"
                placeholder="Khả dụng"
                name="available_point"
                @onFilter="onFilterChange"
              />
            </v-col>
            <v-col cols="12" md="6">
              <SelectCity
                class="c-input-xs input-filter"
                name="id_city"
                :label="$t('labels.city')"
                @onChange="onFilterChange"
              />
            </v-col>
            <v-col cols="12" md="6">
              <SelectCounty
                class="c-input-xs input-filter"
                name="id_county"
                :id-city="filters.id_city"
                :label="$t('labels.district')"
                @onChange="onFilterChange"
              />
            </v-col>
            <v-col cols="12" md="6">
              <SelectWard
                class="c-input-xs input-filter"
                name="id_ward"
                :id-county="filters.id_county"
                :id-city="filters.id_city"
                :label="$t('labels.ward')"
                @onChange="onFilterChange"
              />
            </v-col>
            <v-col cols="12" md="6">
              <InputFilter
                :label="$t('labels.address')"
                :placeholder="$t('labels.address')"
                name="address"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
  name: "VoucherCreateWithFilter",
  components: {
    SelectCity: () => import("@/components/common/SelectCity"),
    SelectCounty: () => import("@/components/common/SelectCounty"),
    SelectWard: () => import("@/components/common/SelectWard"),
    InputFilter: () => import("@/components/table/InputFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
  },
  props: {},
  data: () => ({
    item: {
      note: null,
      voucher_type: null,
      voucher_value: "",
      discount_from: "",
      is_discount_sale_product: 0,
      start_time: null,
      end_time: null,
    },
    filters: {},
    total: 0,
  }),
  watch: {
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  computed: {
    currentDate() {
      return moment().format("YYYY-MM-DD");
    },
    voucherTypeOptions() {
      return [
        {
          text: "Theo %",
          color: "primary--text",
          value: 2,
        },
        {
          text: "Theo giá trị",
          color: "success--text",
          value: 1,
        },
      ];
    },
    genderOptions() {
      return [
        {
          text: "Nam",
          value: 1,
        },
        {
          text: "Nữ",
          value: 2,
        },
      ];
    },

    isDisabledAllBtn() {
      return (
        !this.item.voucher_type ||
        !this.item.voucher_value ||
        !this.item.start_time ||
        !this.item.end_time ||
        this.total === 0
      );
    },
  },
  mounted() {},
  methods: {
    closeDialog() {
      if (this.hasChange) {
        this.$emit("refreshData", true);
      }
      this.$emit("cancel", true);
    },

    async create() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/pos-create-voucher-by-filter", {
          ...this.item,
          filters: this.filters,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.create_success"));
        this.hasChange = true;
        this.closeDialog();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },

    getList: debounce(function () {
      const filterKeys = Object.keys(this.filters);
      if (filterKeys.length === 0) {
        this.total = 0;
        return false;
      }
      const allKeysAreNull = Object.values(this.filters).every(
        (value) => !value
      );
      if (allKeysAreNull) {
        this.total = 0;
        return false;
      }
      httpClient
        .post("/pos-get-buyers", { ...this.filters, page: 1 })
        .then(({ data }) => {
          this.total = +data.total;
        });
    }, 1000),

    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },

    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
  },
};
</script>

<style scoped></style>
